<template>
  <footer v-if="data" class="o-footer-default">
    <div class="container">
      <div class="o-footer-default__container">
        <nav class="o-footer-default__menu" aria-label="Навигация в подвале сайта">
          <div
            v-for="(firstLevel, idx) in data.menu"
            :key="getUniqueDropboxKey(firstLevel, idx)"
            class="o-footer-default__menu-items"
          >
            <div class="o-footer-default__menu-title">{{ firstLevel.name }}</div>

            <div
              v-for="(secondLevel, idx) in firstLevel.children"
              :key="getUniqueDropboxKey(secondLevel, idx)"
              class="o-footer-default__menu-item"
            >
              <Dropbox
                v-if="secondLevel.children.length && !secondLevel.attributes.url"
                :ref="getUniqueDropboxRef(getUniqueDropboxKey(secondLevel, idx))"
                v-model="dropboxStates[getUniqueDropboxKey(secondLevel, idx)]"
                v-on-click-outside="
                  ($event) => onClickOutsideHandler($event, getUniqueDropboxKey(secondLevel, idx))
                "
                :disabledAutoPosition="disableState"
              >
                <template #activator="{ toggle, close }">
                  <Button
                    css-class="button-icon-text"
                    icon-pos="right"
                    @click="
                      toggle();
                      toggleBodyScroll(getUniqueDropboxKey(secondLevel, idx));
                    "
                  >
                    {{ secondLevel.name }}

                    <template #icon>
                      <i class="icon-main-down"></i>
                    </template>
                  </Button>

                  <div
                    class="dropbox-overlay"
                    @click="
                      close();
                      toggleBodyScroll(getUniqueDropboxKey(secondLevel, idx));
                    "
                  ></div>
                </template>

                <template #header="{ close }">
                  <div class="dropbox-title">{{ secondLevel.name }}</div>

                  <Button
                    aria-label="Navigation"
                    css-class="button-navigation"
                    @click="
                      close();
                      toggleBodyScroll(getUniqueDropboxKey(secondLevel, idx));
                    "
                  >
                    <i class="icon-main-close"></i>
                  </Button>
                </template>

                <div class="o-footer-default__dropbox-items">
                  <template
                    v-for="(thirdLevel, idx) in secondLevel.children"
                    :key="getUniqueDropboxKey(thirdLevel, idx)"
                  >
                    <a
                      class="link-item"
                      v-if="thirdLevel.attributes.url"
                      :href="thirdLevel.attributes.url"
                      :title="thirdLevel.attributes.title ? thirdLevel.attributes.title : undefined"
                      :target="thirdLevel.attributes.outside ? '_blank' : undefined"
                    >
                      {{ thirdLevel.name }}
                    </a>
                  </template>
                </div>
              </Dropbox>

              <template v-else>
                <a
                  v-if="secondLevel.attributes.url"
                  :href="secondLevel.attributes.url"
                  :title="secondLevel.attributes.title ? secondLevel.attributes.title : undefined"
                  :target="secondLevel.attributes.outside ? '_blank' : undefined"
                  class="link-item--rounded"
                >
                  {{ secondLevel.name }}
                </a>
              </template>
            </div>
          </div>
        </nav>

        <div class="o-footer-default__contacts">
          <div v-if="data.data" class="o-footer-default__logo">
            <a class="o-footer-default__logo-link" href="/">
              <img
                :src="`${Logo}`"
                :alt="data.site_name ? data.site_name : undefined"
                :title="data.site_name ? data.site_name : undefined"
                height="31"
                width="200"
                class="o-footer-default__logo-image"
              />
            </a>
          </div>

          <div v-if="data.requisites_details.length" class="o-footer-default__requisites">
            <span v-if="data.site_name">
              © 1993–{{ new Date().getFullYear() }} {{ data.site_name }}
            </span>
            <template v-for="requisites in data.requisites_details">
              <span v-if="requisites.type === 'plain'">
                {{ requisites.value }}
              </span>

              <a
                v-else-if="requisites.type === 'phone'"
                :href="`tel:${usePhone(requisites.value as IComponentPhoneField).htmlPhone.value}`"
              >
                {{ usePhone(requisites.value as IComponentPhoneField).normalizedPhone.value }}
              </a>

              <a v-else-if="requisites.type === 'email'" :href="`mailto:${requisites.value}`">
                {{ requisites.value }}
              </a>
            </template>
          </div>

          <div v-if="data.social_media.length" class="o-footer-default__social">
            <a
              v-for="social in data.social_media"
              :href="social.link"
              target="_blank"
              :aria-label="social.link"
              class="o-footer-default__social-link"
            >
              <i :class="`icon-${social.icon}`" />
            </a>
          </div>
        </div>

        <div v-if="data.data" class="o-footer-default__info">
          <div class="o-footer-default__info-contacts">
            Есть вопросы? Звоните:
            <a v-if="data.data.phone" :href="`tel:${usePhone(data.data.phone).htmlPhone.value}`">
              {{ usePhone(data.data.phone).normalizedPhone.value }}
            </a>
            или пишите
            <a v-if="data.data.mail" :href="`mailto:${data.data.mail}`">
              {{ data.data.mail }}
            </a>
          </div>

          <div v-if="data.data" class="o-footer-default__info-schedule">
            Режим работы: {{ data.data.schedule }}
          </div>

          <div v-if="data.disclaimer" class="o-footer-default__info-disclaimers">
            <span>{{ data.disclaimer }}</span>
            <span>{{ data.disclaimer_2 }}</span>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script lang="ts" setup>
// Vue
import { ref } from 'vue';

// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';
import Dropbox from '@ice-products-ui/vue-library/Dropbox';
import Button from '@ice-products-ui/vue-library/Button';

// Directives
import vOnClickOutside from '@ice-products-ui/vue-library/OnClickOutside';

// Composables
import useDropbox from '~/composables/molecules/useDropbox';
import useDisableScrollbar from '~/composables/dom/useDisableScrollbar';
import usePhone from '~/composables/api/usePhone';
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';

// Types
import type { IComponentPhoneField, IComponentProps } from '~/typings/types/component.types';
import type { TOFooterDefault } from '~/components/O/Footer/Default/OFooterDefault.types';
import type { IFooterMenu } from '~/typings/types/menu.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';

// Schema
import schema from '~/components/O/Footer/Default/OFooterDefault.schema';

// Static
import Logo from '~/public/images/logo.svg';

defineOptions({
  name: 'OFooterDefault',
});

const props = withDefaults(defineProps<IComponentProps<TOFooterDefault>>(), {
  viewName: 'OFooterDefault',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const { disableState } = useDropbox();
const { compensateScrollbar, deCompensateScrollbar } = useDisableScrollbar();

interface DropboxStates<T> {
  [key: string]: T;
}

const dropboxStates = ref<DropboxStates<boolean>>({});
const dropboxRefs = ref<DropboxStates<InstanceType<typeof Dropbox>>>({});

const getUniqueDropboxKey = (item: IFooterMenu, idx: number): string => {
  return `${idx}-${item.name}`;
};

const getUniqueDropboxRef = (key: string) => (el: any) => {
  if (el) {
    dropboxRefs.value[key] = el;
  }
};

const onClickOutsideHandler = (e: MouseEvent | TouchEvent, key: string) => {
  for (const dropboxRefKey in dropboxRefs.value) {
    if (dropboxRefKey === key) {
      dropboxRefs.value[dropboxRefKey].close();
    }
  }
};

function toggleBodyScroll(key: string) {
  disableState.value && dropboxStates.value[key] ? compensateScrollbar() : deCompensateScrollbar();
}

const data = props.data.component;

useComponentSchemaValidator(schema, props);
</script>
